import {
  RegisterPage,
  ForgetPasswordPage,
  LoginPage,
  HomePage,
  MyPage,
  ProfileSettingPage,
  ApplyPage,
  SkinMarkersPage,
  CustomerInfoPage,
  ServiceProviderInfoPage,
  BookPage,
  BookDetailPage,
  NotFoundPage,
  ProfilePage,
  SecurityPage,
  BindPage,
  ThirdPartyPage,
  PhoneVerificationPage,
  EmailVerificationPage,
  ForgotPasswordPage,
  EcommercePage,
  ProductCategoryPage,
  ProductShowcasePage,
  ProductSearchPage,
  ProductSearchResultPage,
  ProductDetailPage,
  ProductShopPage,
  CartPage,
  ShopHomePage,
  ShopRedeemScanPage,
  ShopRedemptionHistoryPage,
} from "@/app/index";

/**
 * @typedef {Object} RouteConfig
 * @property {string} path - 路徑
 * @property {React.ReactElement} component - 組件
 * @property {boolean} auth - 是否需要登入
 * @property {string[]} roles - 角色
 * @property {string} layout - 版型
 */
export const routes = [
  //註冊
  {
    path: "/register",
    component: <RegisterPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "auth",
  },

  //忘記密碼
  {
    path: "/forget-password",
    component: <ForgetPasswordPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "empty",
  },
  //忘記密碼
  {
    path: "/forgot",
    component: <ForgotPasswordPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "auth",
  },
  //登入
  {
    path: "/login",
    component: <LoginPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "auth",
  },

  //第三方登入
  {
    path: "/third-party",
    component: <ThirdPartyPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "empty",
  },
  //手機驗證
  {
    path: "/phone-verification",
    component: <PhoneVerificationPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "auth",
  },
  //信箱驗證
  {
    path: "/email-verification",
    component: <EmailVerificationPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "auth",
  },

  //首頁
  {
    path: "/",
    component: <HomePage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //我的
  {
    path: "/my/:tab?",
    component: <MyPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //資料設定
  {
    path: "/profile-setting",
    component: <ProfileSettingPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  // 基本資料
  {
    path: "/profile-setting/profile",
    component: <ProfilePage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  // 已綁定美業老師名單
  {
    path: "/profile-setting/bind",
    component: <BindPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  // 重設密碼
  {
    path: "/profile-setting/security",
    component: <SecurityPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //申請美業老師
  {
    path: "/profile-setting/apply",
    component: <ApplyPage />,
    auth: true,
    roles: ["customer"],
    layout: "main",
  },
  //膚況標記
  {
    path: "/skin-markers",
    component: <SkinMarkersPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //顧客資訊
  {
    path: "/customer-info/:id",
    component: <CustomerInfoPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //業者資訊
  {
    path: "/service_provider-info/:id",
    component: <ServiceProviderInfoPage />,
    auth: true,
    roles: ["customer", "organization"],
    layout: "main",
  },
  //預約
  {
    path: "/book",
    component: <BookPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //預約單
  {
    path: "/book-detail/:id/:providerId",
    component: <BookDetailPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  // 電商首頁
  {
    path: "/ecommerce",
    component: <EcommercePage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  {
    path: "/product/category/:id",
    component: <ProductCategoryPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  {
    path: "/product/:id",
    component: <ProductDetailPage type="product" />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  // 商品展示
  {
    path: "/product/showcase/:slug",
    component: <ProductShowcasePage type="product" />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  {
    path: "/product/search",
    component: <ProductSearchPage type="product" />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  {
    path: "/product/search/result",
    component: <ProductSearchResultPage type="product" />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  {
    path: "/product/shop/:id",
    component: <ProductShopPage type="product" />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //購物車
  {
    path: "/cart",
    component: <CartPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  // 店家核銷首頁
  {
    path: "/shop/:id",
    component: <ShopHomePage />,
    auth: true,
    roles: ["service_provider", "organization"],
    layout: "empty",
  },
  // 店家驗票
  {
    path: "/shop/:id/redeem-scan",
    component: <ShopRedeemScanPage />,
    auth: true,
    roles: ["service_provider"],
    layout: "empty",
  },
  // 店家驗票紀錄
  {
    path: "/shop/:id/redemption-history",
    component: <ShopRedemptionHistoryPage />,
    auth: true,
    roles: ["service_provider"],
    layout: "empty",
  },
  // not found
  {
    path: "*",
    component: <NotFoundPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "empty",
  },
];
