import { createSlice } from "@reduxjs/toolkit";
import userApi from "@/services/userApi";
import authApi from "@/services/authApi";
import { removeToken } from "@/utils";
import userSchema from "@/schema/userSchema";

// Selectors
export const selectProfile = (state) => state.user.profile;
export const selectIsAuth = (state) => state.user.isAuth;
export const selectCurrentRole = (state) => state.user.currentRole;

// Initial state helpers
const getInitialProfile = () =>
  localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : null;

const getInitialIsAuth = () =>
  localStorage.getItem("isAuth") &&
  JSON.parse(localStorage.getItem("isAuth")) === true;

const getInitialCurrentRole = () =>
  localStorage.getItem("currentRole")
    ? JSON.parse(localStorage.getItem("currentRole"))
    : {
        role: userSchema.CURRENT_ROLE.CUSTOMER,
        identityId: null,
      };

// Slice
const userStore = createSlice({
  name: "user",
  initialState: {
    profile: getInitialProfile(),
    isAuth: getInitialIsAuth(),
    currentRole: getInitialCurrentRole(),
  },
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setCurrentRole: (state, action) => {
      state.currentRole = action.payload;
    },
  },
});

// Actions
export const createActions = (dispatch) => ({
  userLogin: async (account, password, from) => {
    try {
      if (from) {
        localStorage.setItem("from", from);
      }
      await authApi.login(account, password);
      await createActions(dispatch).fetchUserProfile();
      createActions(dispatch).setIsAuth(true);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  thirdPartyLogin: async (platform, from) => {
    try {
      const baseUrl = `${window.location.origin}/third-party`;
      const res = await authApi[`${platform}Login`](baseUrl);
      window.location.href = res.redirect_url;
      if (from) {
        localStorage.setItem("from", from);
      }
    } catch (error) {
      console.error(error);
    }
  },

  fetchUserProfile: async () => {
    try {
      const res = await userApi.getUser();
      localStorage.setItem("profile", JSON.stringify(res));
      dispatch(setProfile(res));
      return res;
    } catch (error) {
      console.error(error);
      dispatch(setProfile(null));
      localStorage.removeItem("profile");
      return null;
    }
  },

  setIsAuth: (isAuth) => {
    localStorage.setItem("isAuth", JSON.stringify(isAuth));
    dispatch(setIsAuth(isAuth));
  },

  setCurrentRole: (role, identityId, orgId, roleLevel) => {
    localStorage.setItem(
      "currentRole",
      JSON.stringify({ role, identityId, orgId, roleLevel }),
    );
    dispatch(setCurrentRole({ role, identityId, orgId, roleLevel }));
  },

  userLogout: async () => {
    removeToken();
    localStorage.removeItem("profile");
    localStorage.removeItem("isAuth");
    localStorage.removeItem("bookingCodeShown");
    localStorage.removeItem("cart");
    localStorage.removeItem("currentRole");
    localStorage.removeItem("from");
    localStorage.setItem("isLogout", true);

    dispatch(setProfile(null));
    dispatch(setIsAuth(false));
    dispatch(
      setCurrentRole({
        role: userSchema.CURRENT_ROLE.CUSTOMER,
        identityId: null,
        orgId: null,
        roleLevel: null,
      }),
    );
  },
});

export const { setProfile, setIsAuth, setCurrentRole } = userStore.actions;
export default userStore.reducer;
