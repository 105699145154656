import PropTypes from "prop-types";

import { useUserStore } from "@/hooks/useUserStore";
import { useNavigate } from "react-router-dom";

const AuthLayout = ({ children }) => {
  const userStore = useUserStore();
  const navigate = useNavigate();
  const handleLogoClick = async () => {
    if (userStore.isAuth) {
      await userStore.userLogout();
    }
    navigate("/login");
  };
  return (
    <div className="flex min-h-screen flex-col items-center justify-start">
      <div className="w-full max-w-md space-y-8 rounded bg-white">
        <div className="flex items-center justify-center bg-white sm:min-h-0">
          <div className="relative w-full max-w-md space-y-8">
            <div className="my-12 flex justify-center">
              <div className="flex justify-center text-center text-[18px] leading-[3]">
                <div onClick={handleLogoClick}>
                  <img
                    src="/images/logo.svg"
                    className="h-20"
                    alt="stag-logo"
                  />
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

// 使用 PropTypes 進行驗證
AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
