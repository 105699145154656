import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  House,
  Chats,
  CalendarDots,
  Storefront,
  UserCircle,
} from "@phosphor-icons/react";

const navItems = [
  { path: "/", label: "首頁", icon: <House size={26} /> },
  { path: "/##", label: "社群", icon: <Chats size={26} /> },
  {
    path: "/book",
    label: "預約",
    icon: <CalendarDots size={26} />,
    isActive: (location) =>
      location.pathname.includes("/book") ||
      location.pathname.includes("/book-detail"),
  },
  {
    path: "/ecommerce",
    label: "電商",
    icon: <Storefront size={26} />,
    isActive: (location) =>
      (location.pathname.includes("/product") &&
        !location.pathname.includes("/my")) ||
      location.pathname.includes("/ecommerce"),
  },
  {
    path: "/my",
    label: "我的",
    icon: <UserCircle size={26} />,
    isActive: (location) =>
      location.pathname.includes("/my") ||
      location.pathname.includes("/profile-setting") ||
      location.pathname.includes("/apply-beautician"),
  },
];

const BottomNavigation = () => {
  const location = useLocation();

  return (
    <div className="fixed bottom-0 z-30 mx-auto flex w-full justify-around bg-white px-1 py-2 shadow-up">
      {navItems.map(({ path, label, icon, isActive }) => (
        <Link key={path} to={path}>
          <div
            className={`flex flex-col items-center gap-0.5 ${
              (isActive ? isActive(location) : location.pathname === path)
                ? "text-[#F98B8B]"
                : "text-[#B7B7B7]"
            }`}
          >
            {icon}
            <span
              className={`!text-[10px] text-xs ${(isActive ? isActive(location) : location.pathname === path) ? "text-[#F98B8B]" : "text-[#B7B7B7]"}`}
            >
              {label}
            </span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default BottomNavigation;
